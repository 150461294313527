<template>
  <div id="teamKpiDeatil">
    <el-row>
      <el-col>
        <el-button size="mini" @click="downInfoExp" :loading="btnLoading"
          >导出</el-button
        >
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="page.size"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          style="padding-right: 0px"
        ></el-pagination>
      </el-col>
      <el-col>
        <table
          class="
            el-table
            tabBorder
            el-table--fit el-table--enable-row-hover
            digTable
            specialS
          "
          cellpadding="0"
          cellspacing="0"
        >
          <thead class="has-gutter">
            <tr class="tableTdTrH">
              <th v-for="(i, index) in headerOption" :key="index" scope="col">
                {{ i }}
              </th>
            </tr>
          </thead>
          <tbody>
            <p v-show="isData" style="text-align: center; color: #909399">
              暂无数据
            </p>
            <tr
              v-for="(i, index) in iscusoption1"
              :key="index"
              class="tableTyTrH"
            >
              <td
                v-for="(ie, ins) in iscusoption1[index].rowData"
                :key="ins"
                valign="middle"
              >
                <template v-if="ie.startsWith('[')">
                  <pre
                    style="
                      line-height: 20px;
                      height: calc(100% - 24px);
                      display: flex;
                      align-items: center;
                    "
                    :style="
                      ie.indexOf('commentColumnId') > 0
                        ? ''
                        : 'cursor: pointer;color: #2b80ff;'
                    "
                    v-html="serializeIE(ie)"
                    @click="goNav(ie)"
                  ></pre>
                </template>

                <template v-else>
                  <span
                    v-html="internalM(ie, ins)"
                    @click="nativalNav(ie, ins)"
                    style="display: flex; height: 100%; align-items: center"
                  ></span>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="page.size"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          style="padding-right: 0px"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon" :style="drawerTitle == '人才' ? 'padding:0' : ''">
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
          v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="customId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
        <div
          v-if="
            drawerTitle === '项目人才备注' ||
            drawerTitle === '简历推荐' ||
            drawerTitle === '客户面试_初' ||
            drawerTitle === '客户面试_终' ||
            drawerTitle === '客户面试_全部' ||
            drawerTitle === 'Offer' ||
            (drawerTitle === '入职' && drawerVisible)
          "
        >
          <el-row>
            <el-col>
              <el-button size="mini" @click="downInfoExp" :loading="btnLoading"
                >导出</el-button
              >
              <el-pagination
                :hide-on-single-page="page1.total > 10 ? false : true"
                v-if="page1.total > 0"
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="page1.current"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="page1.size"
                layout="total,prev, pager, next,sizes"
                :total="page1.total"
                style="padding-right: 0px"
              ></el-pagination>
            </el-col>
            <el-col>
              <table
                class="
                  el-table
                  tabBorder
                  el-table--fit el-table--enable-row-hover
                  digTable
                  specialS
                "
                cellpadding="0"
                cellspacing="0"
              >
                <thead class="has-gutter">
                  <tr class="tableTdTrH">
                    <th
                      v-for="(i, index) in headerOption1"
                      :key="index"
                      scope="col"
                    >
                      {{ i }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <p v-show="isData" style="text-align: center; color: #909399">
                    暂无数据
                  </p>
                  <tr
                    v-for="(i, index) in iscusoption2"
                    :key="index"
                    class="tableTyTrH"
                  >
                    <td
                      v-for="(ie, ins) in iscusoption2[index]"
                      :key="ins"
                      valign="middle"
                    >
                      <template v-if="ie.startsWith('[')">
                        <pre
                          style="
                            line-height: 20px;
                            height: calc(100% - 24px);
                            display: flex;
                            align-items: center;
                          "
                          :style="
                            ie.indexOf('commentColumnId') > 0
                              ? ''
                              : 'cursor: pointer;color: #2b80ff;'
                          "
                          v-html="serializeIE(ie)"
                          @click="goNav(ie)"
                        ></pre>
                      </template>

                      <template v-else>
                        <span
                          v-html="internalM(ie, ins)"
                          @click="nativalNav(ie, ins)"
                          style="
                            display: flex;
                            height: 100%;
                            align-items: center;
                          "
                        ></span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-pagination
                :hide-on-single-page="page1.total > 10 ? false : true"
                v-if="page1.total > 0"
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="page1.current"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="page1.size"
                layout="total,prev, pager, next,sizes"
                :total="page1.total"
                style="padding-right: 0px"
              ></el-pagination>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { teamKpiItemDetail, teamKpiItemDetailExport } from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import resumeDetails from "../resumeDetail";
export default {
  name: "teamKpiDeatil",
  props: ["searchData"],
  components: { resumeDetails },
  data() {
    return {
      resumeId: null,
      btnLoading: false,
      baseUrl: ApiBaseUrl,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      drawerVisible: false,
      headerOption: [],
      isData: false,
      iscusoption1: [],
      drawerTitle: "",
      page1: {
        current: 1,
        size: 10,
        total: 0,
      },
      headerOption1: [],
      isData1: false,
      iscusoption2: [],
      searchData1: {},
    };
  },
  created() {
    this.loadList();
    this.searchData1 = this.searchData;
    this.loadList1();
  },
  methods: {
    handleCurrentChange(val) {
      this.page.current = val;
      this.loadList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.loadList();
    },
    loadList() {
      $(".el-drawer__body").scrollTop(0);
      this.searchData.page = this.page;
      teamKpiItemDetail(this.searchData).then((res) => {
        if (res.success) {
          this.headerOption = res.result.headers;
          this.iscusoption1 = res.result.data;
          this.page.total = res.result.total;
        }
      });
    },
    downInfoExp() {
      //导出
      this.btnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      teamKpiItemDetailExport(this.searchData).then((res) => {
        if (res.success) {
          if (!setData) {
            isconfirm = false;
            location.href = this.baseUrl + res.result;
          }
        }
        this.btnLoading = false;
      });
    },
    //关闭抽屉
    handleClose() {
      this.drawerVisible = false;
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.resumeId = id;
    },
    internalM(i, index) {
      let _ie;
      try {
        _ie = JSON.parse(i);
      } catch (e) {
        return i;
      }
      if (Array.isArray(_ie)) {
        return;
      }

      return `<a class="aStyle">${_ie.name ? _ie.name : _ie}</a>`;
    },
    serializeIE(ie) {
      //备注详情字段处理
      try {
        const arr = JSON.parse(ie);
        let str = [];
        if (Array.isArray(arr)) {
          if (ie.includes("commentColumnId")) {
            str = arr.filter((a) => {
              return a.value;
            });
            return str
              .map(
                (a) =>
                  `${a.columnTitle == "详情" ? "" : a.columnTitle + "："}${
                    a.value
                  }`
              )
              .join("\n");
          } else {
            return arr.map((a) => `${a.name}`).join("\n");
          }
        } else {
          // 不是数组就走catch
          throw 1;
        }
      } catch (e) {
        return ie;
      }
    },
    nativalNav(i, index) {
      let headerText = null;
      if (
        this.drawerTitle === "项目人才备注" ||
        this.drawerTitle === "简历推荐" ||
        this.drawerTitle === "客户面试_初" ||
        this.drawerTitle === "客户面试_终" ||
        this.drawerTitle === "客户面试_全部" ||
        this.drawerTitle === "Offer" ||
        this.drawerTitle === "入职"
      ) {
        headerText = this.headerOption1[index];
      } else {
        headerText = this.headerOption[index];
      }

      // debugger
      try {
        let _da = JSON.parse(i);
        if (headerText === "候选人") {
          this.drawerTitle = "人才";
          this.drawerVisible = true;
          this.customId = _da.relativeId;
        } else if (headerText === "项目名称") {
          let _router = this.$router.resolve({
            path: "/project/details",
            query: { id: _da.relativeId },
          });
          window.open(_router.href, "_blank");
        } else if (headerText === "客户名称") {
          this.drawerTitle = "客户";
          this.drawerVisible = true;
          this.customId = _da.relativeId;
        } else if (
          headerText === "项目人才备注" ||
          headerText === "简历推荐" ||
          headerText === "客户面试_初" ||
          headerText === "客户面试_终" ||
          headerText === "客户面试_全部" ||
          headerText === "Offer" ||
          headerText === "入职"
        ) {
          this.drawerTitle = headerText;
          this.drawerVisible = true;
          this.searchData1 = {
            searchId: _da.relativeId,
            searchType: this.filter.searchType,
            projectId: this.searchData.projectId
              ? this.searchData.projectId
              : "",
            start: this.searchData.start,
            end: this.searchData.end,
            itemType: headerText,
          };
        }
      } catch (e) {
        return;
      }
    },
    loadList1() {
      this.searchData1.page = this.page1;
      teamKpiItemDetail(this.searchData1).then((res) => {
        if (res.success) {
          this.headerOption1 = res.result.headers;
          this.iscusoption2 = res.result.data;
          this.page1.total = res.result.total;
        }
      });
    },
    handleCurrentChange1(val) {
      this.page1.current = val;
      this.loadList1();
    },
    //pageSize 改变时会触发
    handleSizeChange1(val) {
      this.page1.size = val;
      this.page1.current = 1;
      this.loadList1();
    },
  },
};
</script>

<style scoped lang="scss">
.tableTdTrH {
  line-height: 36px;
  height: 36px;
  font-weight: bold;
  background: #f5f7fa;
}
.tableTyTrH {
  height: auto;
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    background-color: #fff;
  }
}
.fixedLeft {
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 0; /* 首行永远固定在左侧 */
    z-index: 4;
  }
}
.hasShadow {
  td:nth-child(2),
  th:nth-child(2) {
    left: 120px;
    box-shadow: 5px 0 10px -5px #ddd;
  }
}
.is_fixed {
  position: fixed;
  top: 44px;
  z-index: 9;
}
.kpiSet {
  height: 28px;
  /*padding: 10px 15px;*/
  border: 1px solid #ededed;
  width: 85px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  /* margin-left: 15px; */
  display: block;
  float: left;
  color: #606266;
  i {
    /*color: rgb(187, 187, 187);*/
    font-size: 14px;
  }

  &:hover {
    color: #333333;
    border: 1px solid #999999 !important;
  }
}

pre {
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  white-space: pre-line;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
thead {
  // border-top: 1px solid #ebeef5;
  // border-bottom: 1px solid #ebeef5;
}
thead,
tbody {
  display: block;
}

th {
  padding: 0;
  /*flex: 1;*/
  background-color: rgb(245, 247, 250);
  font-weight: normal;
}

tr {
  height: 50px;
  line-height: 50px;
  /*display: flex;*/
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
}

td,
th {
  display: inline-block;
  width: 150px;
  /*flex: 1;*/
  padding: 0 15px;
}

.digTable tr {
  display: flex;
}

.digTable th,
.digTable td {
  flex: 1;
  /*width: 250px !important;*/
}

.iconshaixuan:hover {
  color: #ff3f3f;
  cursor: pointer;
}

.specialS td {
  // overflow: auto;
  white-space: normal;
}
</style>

<style lang="less">
.aStyle {
  cursor: pointer;
  color: #2b80ff;

  &:hover {
    text-decoration: underline;
  }
}

.select_check {
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
  }
  .el-checkbox-group {
    height: 200px;
    overflow-y: auto;
    padding: 10px 15px 0 15px;
  }
}

.diaDiv .el-dialog {
  padding-bottom: 60px;
}

.diaDiv .el-dialog__body {
  border-bottom: none;
}
.showCheckbox {
  .el-checkbox__label {
    color: #606266 !important;
  }
}
</style>

<style lang="scss">
.el-cascader .el-input .el-input__inner {
  height: 32px !important;
}
.configer {
  padding: 0 !important;
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .select_check {
    background: white;
    height: 255px;
    position: relative;
    .kpiButton {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 10px;
      padding: 10px;
      text-align: right;
      border-top: 1px solid #ededed;
      background: #f0f0f0;
    }
  }
}
.dinput {
  .el-input--suffix .el-input__inner {
    border-radius: 5px 0px 0px 5px;
  }
}
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input--suffix .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
</style>
