<template>
  <section class="box-height teamKpi scollDom" style="padding: 0 15px">
    <div style="background-color: #fff; border: 1px #e5e5e5 solid">
      <el-form
        :model="filter"
        ref="filter"
        style="background-color: #fff"
        label-width="60px"
      >
        <el-row style="padding: 15px 0 0">
          <div class="clearfix" style="display: flex">
            <el-form-item
              label="时间："
              prop="time"
              style="margin-bottom: 20px"
            >
              <el-date-picker
                v-model="filter.time"
                type="daterange"
                range-separator="至"
                @change="
                  needLastDay = false;
                  loadInfo();
                "
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              prop="searchIdList"
              label="对象："
              class="fl"
              style="margin-bottom: 20px; margin-left: 20px"
            >
              <el-select
                v-model="filter.searchType"
                placeholder="请选择大类"
                filterable
                @change="changeOption"
                style="width: 90px"
                class="dinput"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <el-cascader
                class="searchCascader dinputr"
                style="min-width: 240px"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="searchIdList"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              >
              </el-cascader>
              <el-checkbox
                class="showCheckbox"
                style="width: 100px; margin-left: 15px"
                @change="showAccount"
                v-model="filter.show"
                >停用账户</el-checkbox
              >
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-button
              style="margin-left: 10px"
              size="mini"
              @click="exportKpi"
              :loading="exportBtnLoading"
              >导出</el-button
            >
            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div class="box-title" style="padding-bottom: 0">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
                height: '35px',
                'border-top': '1px #e5e5e5 solid',
              }"
              v-horizontal-scroll
              border
              lazy
              :data="ListData"
              v-sticky="{ top: 0, parent: '.scollDom' }"
              class="tabBorder custor notableBorder"
              v-loading="listLoading"
              style="margin: 10px 0 10px; border-top: 0"
              row-key="projectName"
              default-expand-all
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column
                label="对象"
                fixed
                align="left"
                prop="name"
                width="200"
              >
              </el-table-column>
              <el-table-column label="操作项目数" align="left" width="300">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.name ? 'tabHref' : ''"
                    @click="
                      scope.row.name
                        ? scope.row.projectCount > 0
                          ? showDraw(scope.row, '操作项目数')
                          : ''
                        : scope.row.projectName > 0
                        ? showDraw(scope.row, '操作项目数')
                        : ''
                    "
                    >{{
                      scope.row.name
                        ? scope.row.projectCount
                        : scope.row.projectName
                    }}</span
                  >
                  <i
                    class="el-icon-arrow-down"
                    v-if="
                      scope.row.name &&
                      scope.row.children.length == 0 &&
                      scope.row.projectCount > 0
                    "
                    style="padding: 5px; margin-left: 8px; border: 0"
                    @click="getKpiList(scope.row, scope.$index)"
                  ></i>
                  <i
                    class="el-icon-arrow-up"
                    v-if="
                      scope.row.name &&
                      scope.row.children.length > 0 &&
                      scope.row.projectCount > 0
                    "
                    style="padding: 5px; margin-left: 8px"
                    @click="closeList(scope.row, scope.$index)"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column label="加入项目" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.addProjectCount > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.addProjectCount > 0
                        ? showDraw(scope.row, '加入项目')
                        : ''
                    "
                    >{{ scope.row.addProjectCount }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="项目人才备注" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.resumeCommentCount > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.resumeCommentCount > 0
                        ? showDraw(scope.row, '项目人才备注')
                        : ''
                    "
                    >{{ scope.row.resumeCommentCount }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="简历推荐" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.recommendCount > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.recommendCount > 0
                        ? showDraw(scope.row, '简历推荐')
                        : ''
                    "
                    >{{ scope.row.recommendCount }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="客户面试_初" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.firstInterview > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.firstInterview > 0
                        ? showDraw(scope.row, '客户面试_初')
                        : ''
                    "
                    >{{ scope.row.firstInterview }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="客户面试_终" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.finalInterview > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.finalInterview > 0
                        ? showDraw(scope.row, '客户面试_终')
                        : ''
                    "
                    >{{ scope.row.finalInterview }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="客户面试_全部" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.allInterview > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.allInterview > 0
                        ? showDraw(scope.row, '客户面试_全部')
                        : ''
                    "
                    >{{ scope.row.allInterview }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="Offer" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.offerCount > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.offerCount > 0
                        ? showDraw(scope.row, 'Offer')
                        : ''
                    "
                    >{{ scope.row.offerCount }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="入职" align="left" width="120">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.entryCount > 0 ? 'tabHref' : ''"
                    @click="
                      scope.row.entryCount > 0
                        ? showDraw(scope.row, '入职')
                        : ''
                    "
                    >{{ scope.row.entryCount }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0 && !listLoading"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
              style="margin-bottom: 20px"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        {{ drawerTitle }}
      </div>
      <div class="drawerCon">
        <teamKpiDeatil
          ref="teamKpiDeatil"
          v-if="drawerVisible"
          :searchData="searchData"
        ></teamKpiDeatil>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerVisible1"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        操作项目数（{{ this.ListData1.length }}个）
      </div>
      <div class="drawerCon">
        <div>
          <el-button size="mini" @click="exportKpi1" :loading="exportBtnLoading"
            >导出</el-button
          >
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
              'border-top': '1px #e5e5e5 solid',
            }"
            v-horizontal-scroll
            border
            :data="ListData1"
            v-sticky="{ top: 0, parent: '.scollDom' }"
            class="tabBorder custor notableBorder"
            v-loading="listLoading"
            style="
              margin: 10px 0 10px;
              border-top: 0;
              border-left: 1px #efefef solid;
            "
          >
            <el-table-column
              label="对象"
              fixed
              align="left"
              prop="departName"
              width="200"
            >
            </el-table-column>
            <el-table-column label="项目名称" align="left" width="300">
              <template slot-scope="scope">
                <router-link
                  class="tabHref"
                  target="_blank"
                  style="text-decoration: none"
                  :to="{
                    path: '/project/details',
                    query: { id: scope.row.projectId },
                  }"
                  >{{ scope.row.projectName }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              align="left"
              prop="createdTime"
              width="120"
            >
            </el-table-column>
            <el-table-column
              label="最近操作时间"
              align="left"
              prop="lastOperateTime"
              width="120"
            >
            </el-table-column>
            <el-table-column label="项目人才备注" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.resumeCommentCount > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.resumeCommentCount > 0
                      ? showDraw(scope.row, '项目人才备注')
                      : ''
                  "
                  >{{ scope.row.resumeCommentCount }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="简历推荐" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.recommendCount > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.recommendCount > 0
                      ? showDraw(scope.row, '简历推荐')
                      : ''
                  "
                  >{{ scope.row.recommendCount }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="客户面试_初" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.firstInterview > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.firstInterview > 0
                      ? showDraw(scope.row, '客户面试_初')
                      : ''
                  "
                  >{{ scope.row.firstInterview }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="客户面试_终" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.finalInterview > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.finalInterview > 0
                      ? showDraw(scope.row, '客户面试_终')
                      : ''
                  "
                  >{{ scope.row.finalInterview }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="客户面试_全部" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.allInterview > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.allInterview > 0
                      ? showDraw(scope.row, '客户面试_全部')
                      : ''
                  "
                  >{{ scope.row.allInterview }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="Offer" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.offerCount > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.offerCount > 0 ? showDraw(scope.row, 'Offer') : ''
                  "
                  >{{ scope.row.offerCount }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="入职" align="left" width="200">
              <template slot-scope="scope">
                <span
                  :class="scope.row.entryCount > 0 ? 'tabHref' : ''"
                  @click="
                    scope.row.entryCount > 0 ? showDraw(scope.row, '入职') : ''
                  "
                  >{{ scope.row.entryCount }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  teamKpiPage, //团队KPI分页列表
  permissionTree, //小类
  permissionType, // 大类
  teamKpiExport, //导出团队KPI分页列表
  teamKpiProjectDetail, //按项目维度统计的数据
  exportKpiDataThrough, //导出KPI数透
  projectDetailExport, //导出按项目维度统计的数据
} from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import teamKpiDeatil from "../../components/report/teamKpiDeatil";
export default {
  name: "teamKpi",
  components: { teamKpiDeatil },
  data() {
    return {
      filter: {
        //查询条件
        time: [
          new Date().Format("yyyy-MM-dd"),
          new Date().Format("yyyy-MM-dd"),
        ],
        searchType: "顾问",
        searchIdList: [],
        show: false, // 默认不展示停用数据
      },
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      needLastDay: false,
      options2: [],
      searchIdList: [],
      exportBtnLoading: false,
      kpiPlanInput: {},
      isExportBtnShow: false,
      baseUrl: ApiBaseUrl,
      sourceIds: [],
      optionsList: [],
      ListData: [],
      drawerVisible: false,
      searchData: {},
      drawerTitle: "",
      ListData1: [],
      drawerVisible1: false,
      listLoading: false,
      isDown: false,
    };
  },
  created() {
    this.permissionType();
    this.permissionTree("顾问", true);
    this.weekCheckingIn();
  },
  methods: {
    weekCheckingIn() {
      this.weekChecking_inloading = true;
      var now = new Date();
      var nowTime = now.getTime();
      var day = now.getDay() || 7; // 不加 || 7.周末会变成下周一
      var oneDayTime = 24 * 60 * 60 * 1000;
      var MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
      //调用方法
      this.filter.time[0] = this.formatDate(new Date(MondayTime));
      this.myKpiDetailList();
    },
    formatDate(date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    // 查询
    loadInfo() {
      if (!this.filter.searchType || this.sourceIds.length == 0) {
        if (this.filter.searchType !== "顾问") {
          this.$message.error("考核维度必填，请选择考核维度！");
          this.ListData = [];
          return false;
        }
      }
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 50;
      this.myKpiDetailList();
    },
    changeOption(val) {
      //
      this.searchIdList = "";
      this.sourceIds = [];
      this.pagination.pageNo = 1;
      if (val) {
        this.permissionTree(val);
        if (val == "顾问") {
          this.loadInfo();
        }
      }
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      this.sourceIds = this.searchIdList.map((i) => {
        return i[i.length - 1];
      });
      this.loadInfo();
    },
    //获取分页
    myKpiDetailList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
      const time = `${yesterday.getFullYear()}-${(
        "" +
        (yesterday.getMonth() + 1)
      ).padStart(2, 0)}-${("" + yesterday.getDate()).padStart(2, 0)}`;
      this.kpiPlanInput.start = this.filter.time ? this.filter.time[0] : time;
      this.kpiPlanInput.end = this.filter.time ? this.filter.time[1] : time;
      const page = (this.kpiPlanInput.page = {});
      page.size = this.pagination.pageSize;
      page.current = this.pagination.pageNo;
      // filter.searchIdList 小类
      this.kpiPlanInput.show = true;
      if (this.filter.searchType == "顾问") {
        this.kpiPlanInput.show = !this.filter.show;
        this.kpiPlanInput.searchIds = this.sourceIds;
      } else {
        if (this.filter.searchIdList) {
          this.kpiPlanInput.searchIds = this.sourceIds;
        } else {
          this.kpiPlanInput.searchIds = [];
        }
      }
      this.kpiPlanInput.searchType = this.filter.searchType
        ? this.filter.searchType
        : this.optionsList[0].type;

      teamKpiPage(this.kpiPlanInput).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.kpiData = {};
          if (res.result) {
            this.ListData = res.result.records;
            this.ListData.forEach((item) => {
              item.children = [];
            });
            this.pagination.pageSize = res.result.size;
            this.pagination.total = res.result.total;
          } else {
            this.$message.info(res.message);
          }
        }
        this.listLoading = false;
      });
    },
    //项目维度获取分页
    getKpiList(row, index) {
      let ind = this.ListData.findIndex((item) => {
        return item.id == row.id;
      }); //实际下标
      this.listLoading = true;
      this.isDown = true;
      let request = null;
      if (index) {
        let data = {
          end: row.end,
          searchId: row.id,
          start: row.start,
          searchType: row.searchType,
        };
        this.kpiData = data;
        request = teamKpiProjectDetail(data);
      } else {
        let data = {
          end: row.end,
          searchId: row.id,
          start: row.start,
          searchType: row.searchType,
          itemType: this.searchData.itemType,
        };
        request = teamKpiProjectDetail(data);
      }
      request.then((res) => {
        if (res.success) {
          this.isDown = false;
          if (res.result) {
            if (index !== undefined) {
              let item = this.ListData[ind];
              item.children = res.result;
              this.$set(this.ListData, ind, item);
            } else {
              this.ListData1 = res.result;
            }
            this.listLoading = false;
          } else {
            this.$message.info(res.message);
          }
        }
      });
    },
    closeList(row, index) {
      let ind = this.ListData.findIndex((item) => {
        return item.id == row.id;
      }); //实际下标
      let item = this.ListData[ind];
      item.children = [];
      this.$set(this.ListData, ind, item);
    },
    showAccount() {
      this.permissionTree(this.filter.searchType);
      this.loadInfo();
    },
    // 查询维度
    // 大类
    permissionType() {
      permissionType().then((res) => {
        if (res.success) {
          this.optionsList = res.result;
        }
      });
    },
    // 小类
    permissionTree(val, isfirst) {
      permissionTree({ type: val, showDisableUser: this.filter.show }).then(
        (res) => {
          if (res.success) {
            this.options2 = res.result;
          }
        }
      );
    },
    //回显(多级)
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (
              childrenData[j].children &&
              childrenData[j].children.length > 0
            ) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }

      return childrenEach(treeData, depth);
    },
    //导出
    exportKpi() {
      const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
      const time = `${yesterday.getFullYear()}-${(
        "" +
        (yesterday.getMonth() + 1)
      ).padStart(2, 0)}-${("" + yesterday.getDate()).padStart(2, 0)}`;
      this.kpiPlanInput.start = this.filter.time ? this.filter.time[0] : time;
      this.kpiPlanInput.end = this.filter.time ? this.filter.time[1] : time;
      if (this.kpiPlanInput && this.kpiPlanInput.startDate) {
        var exportKpiPlanInput = this.kpiPlanInput;
        exportKpiPlanInput.page.current = 1;
        exportKpiPlanInput.page.size = this.pagination.total;
      } else {
        var exportKpiPlanInput = {
          start: this.kpiPlanInput.start,
          end: this.kpiPlanInput.end,
          page: { size: this.pagination.total, current: 1 },
          searchIds: [],
          searchType: this.filter.searchType ? this.filter.searchType : "",
        };
        var arr = [];
        this.ListData.forEach((item) => {
          arr.push(item.id);
        });
        exportKpiPlanInput.searchIds = Array.from(new Set(arr));
      }
      this.exportBtnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      if (this.kpiData.sourceId) {
        exportKpiDataThrough(this.kpiData).then((res) => {
          this.exportBtnLoading = false;
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
        });
      } else {
        teamKpiExport(exportKpiPlanInput).then((res) => {
          this.exportBtnLoading = false;
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
        });
      }
    },
    exportKpi1() {
      this.exportBtnLoading = true;
      projectDetailExport({
        end: this.searchData.end,
        searchId: this.searchData.searchId,
        searchType: this.searchData.searchType,
        start: this.searchData.start,
      }).then((res) => {
        this.exportBtnLoading = false;
        if (res.success) {
          location.href = this.baseUrl + res.result;
        }
      });
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.myKpiDetailList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.myKpiDetailList();
    },
    //关闭弹窗
    handleClose() {
      this.drawerVisible = false;
      this.drawerVisible1 = false;
    },
    //详情弹窗
    showDraw(row, status) {
      const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
      const time = `${yesterday.getFullYear()}-${(
        "" +
        (yesterday.getMonth() + 1)
      ).padStart(2, 0)}-${("" + yesterday.getDate()).padStart(2, 0)}`;
      let start,
        end = null;
      start = this.filter.time ? this.filter.time[0] : time;
      end = this.filter.time ? this.filter.time[1] : time;
      this.searchData = {
        searchId: row.id,
        searchType: row.searchType,
        projectId: row.projectId ? row.projectId : "",
        start: start,
        end: end,
        itemType: status,
      };
      if (status == "操作项目数") {
        this.drawerVisible1 = true;
        this.getKpiList(row);
      } else {
        this.drawerVisible = true;
        this.drawerTitle = status;
      }
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
};
</script>